@import '../../index';

.pageContainer {
    display: flex;
    flex-direction: column;

    & :global(.ant-space-item) {
        width: 100%;
    }

    .link {
        color: $primary-300;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
}

.pageContainerMobile {
    width: 100%;
}
