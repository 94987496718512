@import '../../index';

.pageContainer {
    display: flex;
    flex-direction: column;

    :global {
        .ant-form-item-row {
            justify-content: space-between;
        }
    }
}

.pageContainerMobile {
    width: 100%;
}
