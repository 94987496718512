@import '../../index';

.form {
    justify-self: center;
    align-self: center;
    width: 400px;

    .button {
        align-self: flex-end;
    }

    .registrationBlock {
        display: flex;
        gap: 4px;
    }

    :global {
        .ant-form-item-row {
            justify-content: space-between;
        }
    }
}
