@import '../../index';

.pageContainer {
    display: flex;
    flex-direction: column;

    .filters {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 16px;
    }
}

.pageContainerMobile {
    width: 100%;
}
