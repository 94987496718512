@import '../../index';

.wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    max-width: 100wv;

    .title {
        display: block;

        & > :first-child {
            margin-right: 8px;
        }
    }

    .button {
        margin-left: auto;
    }
}
