@import '../../index';

.pageContainer {
    display: flex;
    flex-direction: column;

    & :global(.ant-space-item) {
        width: 100%;
    }
}

.pageContainerMobile {
    width: 100%;
}
