$black: black;
$primary-400: #023E8A;
$primary-300: #0077B6;
$primary-200: #90E0EF;
$primary-100: #CAF0F8;
$white: white;

$header-height: 46px;

.form {
    display: flex;
    flex-direction: column;
    gap: 12px;
    background-color: $white;
    padding: 24px;
}

.actions {
    :global {
        .anticon:hover {
            cursor: pointer;
            color: $primary-300;
        }
    }
}

.pageContainer {
    display: flex;
    background-color: $white;
    overflow-y: auto;
    width: 100%;
    max-width: 1200px;
    height: calc(100vh - $header-height);
    flex-direction: column;
    gap: 24px;
    padding: 48px;

    :global {
        .ant-tag {
            margin-right: 0;
        }
    }
}

.pageContainerMobile {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 24px;
    height: unset !important;
    min-height: calc(100vh - $header-height);
}
