@import '../../index';

.container {
    padding-top: 16px;

    :global {
        .ant-form-item-row {
            justify-content: space-between;
        }
    }
}
