@import '../../index';

.pageContainer {
    display: flex;
    flex-direction: column;

    .buttonsWrapper {
        display: flex;
        flex-direction: row;
        gap: 12px;
        height: 100%;
        align-items: center;
    }

    .details {
        display: flex;
        flex-direction: column;
        gap: 12px;
        max-width: 400px;
    }

    .detailsRow {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}

.pageContainerMobile {
    width: 100%;
}
