@import '../../../index';

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background-color: $primary-300;
}

.isMobile {
    .contentWrapper {
        width: 100%;
        padding-left: unset !important;
    }
}

.loggedIn {
    .contentWrapper {
        width: 100%;
        padding-left: 220px;
    }

    &.wrapper {
        background-color: $white;
    }
}

.contentWrapper {
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
    flex: 1;
}
