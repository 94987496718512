@import '../../index';

.pageContainer {
    display: flex;
    flex-direction: column;
}

.pageContainerMobile {
    width: 100%;
}
