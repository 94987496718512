@import '../../../index';

.container {
    display: flex;
    flex-direction: column;

    .form {
        padding: 12px 0 24px 12px;
    }

    .signBlock {
        display: flex;
        flex-direction: column;
    }

    .form {
        & > div, & > p {
            max-width: 400px;
            margin-bottom: unset;
        }

        & > .checkboxBlock {
            max-width: 800px;

            :global {
                .ant-checkbox {
                    margin-bottom: auto;
                    margin-top: 2px;
                }
            }
        }
    }

    .pdfWrapper {
        max-height: 40vh;
        overflow-y: scroll;
    }
}
