@import '../../index';

.wrapper {
    display: block;
    width: 100%;
}

.wrapperMobile {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.tile {
    display: flex;
    flex-direction: column;
    gap: 4px;
    flex: 1 1;
    min-width: 50%;
    padding: 24px;
    border-radius: 8px;
    border: 1px solid $primary-300;
    background: $white;
    cursor: pointer;
    width: 100%;

    .listRow {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        min-height: 50px;

        &:not(:last-child) {
            border-bottom: 0.5px solid $primary-400;
        }

        & .cell {
            width: unset;
        }
    }
}
