@import '../../index';

.container {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    padding: 0 32px;
    gap: 16px;
    background-color: $white;
    height: $header-height;
    z-index: 999;
    box-shadow: 0 0 5px $black;

    .logo {
        cursor: pointer;
    }

    .leftMenu {
        background-color: $black;
        position: absolute;
        top: $header-height;
        left: 0;
        width: 228px;
        height: calc(100vh - $header-height);
    }

    .menu {
        width: 100%;
    }

    .hamburgerMenu {
        margin-left: auto;
    }

    .menuIcon {
        cursor: pointer;
    }

    .menuContent {
        display: block;
        position: absolute;
        z-index: 9999;
        top: 46px;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: white;
    }
}

.containerMobile {
    position: sticky;
    top: 0;
    left: 0;
}
